/* Colors */
@primary: #222;

@brand-orange: rgb(244, 121, 32);
@brand-green:  rgb(133, 196, 65);
@brand-pink:   rgb(238, 61, 138);
@brand-grey:   #CECDCE;

@primary-reverse: @brand-green;
@secondary: @brand-green;

@primary-reverse-light: lighten(@primary-reverse, 30%);
@primary-reverse-dark: darken(@primary-reverse, 20%);
@highlight: darken(@secondary, 10%);
@link-color: @primary-reverse-dark;
@link-color-hover: lighten(@link-color, 10%);
@link-color-dark: darken(@link-color, 5%);
@link-color-background: lighten(@highlight, 70%);

@body-text-color: #222;
@heading-color: @primary-reverse-dark;

/* Fonts */
@font-size-sm: 12px;
@font-size-base: 14px;
@font-size-lg: 16px;
@font-face-base: "Open Sans",sans-serif;
@font-face-headings: "Open Sans",sans-serif;

@screen-lg: 1200px;
@screen-md: 1024px;

html {
    -webkit-font-smoothing: auto;
}

body {
    background: #fff;
    color: @body-text-color;
    font-family: @font-face-base;
    font-size: @font-size-base;
}

h1,
h2,
h3,
h4,
h5, {
    font-family: @font-face-headings;
    color: @heading-color;
}

input[type=text] {
    /* To remove safari inner shadow */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.search-box-mobile {
    background: @primary-reverse;
}

.pagination > .active > a,
a {
    color: @link-color;

    &:hover,
    &:focus {
        color: @link-color-hover;
    }
}

.pagination > li > a:hover,
.pagination > li > a:focus {
    color: @link-color-hover;
    text-decoration: underline;
}

/* Primary colour */
#services-search .services-search-row {
    background-color: @brand-orange;
    background-image: url(/assets/img/header.svg);
    background-size: auto 100%;
    background-position: left center;
}

a.crisis .crisis-heading {
    background-color: @primary-reverse !important;

    div,
    .phone-link,
    .faux-link {
        color: @body-text-color !important;
    }

    &:hover {
        background-color: lighten(@primary-reverse, 10%) !important;
    }
}

.entity-list-item-mobile .title-light {
    color: @primary-reverse-dark;
}

.intro-page-mobile h3 {
    padding: 10px;
    padding-top: 0;
    text-align: center;
    margin: 0;
    background-color: @primary-reverse;
    color: @body-text-color;
}

#leaflet-map .map-down-arrow {
    border-top-color: @brand-orange;
}

.services-search-row .services-search-row-auxiliary {
    border-left-color: @brand-orange;
}

.menu-tabs-mobile > a,
.menu-tabs-mobile > li,
.menu-tabs-mobile a,
.menu-tabs-mobile li,
.menu-tabs-mobile a,
.menu-tabs-mobile li,
.btn-default {
    color: #fff;
    background-color: @primary;
    border-color: @primary;
}

.menu-tabs-mobile .active > a,
.menu-tabs-mobile .active > li,
.menu-tabs-mobile a:hover,
.menu-tabs-mobile li:hover,
.menu-tabs-mobile a:focus,
.menu-tabs-mobile li:focus,
.btn-default:hover,
.btn-default:focus {
    background-color: @highlight !important;
    border-color:  @highlight !important;
    color: darken(@primary-reverse, 33%) !important;
}

.btn-primary-dark {
    background-color: @brand-orange;
    color: @primary;
}

.btn-primary-dark:hover,
.btn-primary-dark:focus {
    background-color: darken(@brand-orange, 5%);
    color: @primary;
}

.btn-primary {
    color: #fff;
    background-color: @primary;
    border-color: @primary;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    background-color: @highlight;
    border-color: @highlight;
    color: darken(@primary-reverse, 33%);
}

#advancedSearchMobileContainer .form-buttons .cancel-advanced-search {
    border-color: @primary-reverse;
}

.search-services-box,
.search-locations-box {
    border-color: #fff;
}

.search-services-box {
    border-right: @primary 1px solid;
}

.services-search-row .services-search-row-main .help-block {
    color: @primary;
    font-size: @font-size-sm;
    grid-column-start: 1;
    grid-column-end: -1;
}

.directory-details-top {
	background-color: #fff;

    .backToServiceTypesListLink .fa {
        color: @link-color;
    }
}

.top-panel-mobile,
#totalRecords_container {
    background-color: @primary;
    color: #fff;
}

.top-panel-mobile .back-menu-mobile {
    color: #fff !important;
}

.directory-details-controls a {
    background-color: @primary;
    color: #fff;
    border-color: @primary-reverse-dark;

    &:hover,
    &:focus {
        background-color: @primary-reverse-dark;
    }

    &:focus {
        text-decoration: underline;
    }
}


.directory-details-body-tabs {

    a,
    li,
    {
        background-color: @primary;
        color: #fff !important;
    }

    li:first-child {
        border-right: 1px solid @primary !important;
    }

    li:last-child {
        border-left: 1px solid @primary !important;
    }

    .active > a,
    .active > li,
    a:hover,
    li:hover,
    a:focus,
    li:focus {
        background-color: @primary-reverse-dark !important;
    }
}


#footer {
    background-color: @primary-reverse;
    color: @primary;

    a {
        color: darken(@primary-reverse, 33%);
    }

}

/* Secondary colour */
#leaflet-map .map-info-overlay {
    background-color: #fff;
    color: @primary;
}

.entity-list-item .green-heading {
    background-color: @brand-grey !important;

    a {
        color: @primary !important;
    }
}

.phone-link {
    color: @link-color !important;
    background-color: @link-color-background;
}

.backToResultsListLink .fa,
.back-menu-mobile .fa-arrow-left {
    color: @link-color !important;
}

.back-menu-mobile {
    color: @primary !important;
}

.back-menu-mobile:hover,
.back-menu-mobile:focus {
    text-decoration: underline !important;

    .fa-arrow-left {
        color: #fff !important;
    }

}

.label-round.label-success {
    color: @primary-reverse-dark;
    border-color: @primary-reverse-dark;
}

.entity-list-item-mobile,
.directory-details-info-row {
    font-size: @font-size-base !important;
}

.entity-list-item-mobile .title-dark,
#leaflet-map .map-info-overlay p.map-message {
    font-size: @font-size-lg !important;
}

#footer {
    .developer-credit {
        border-top: 1px solid @primary-reverse-light;
    }

    .logo-wrapper {
        text-align: right;

        img {
            width: 100%;
            max-width: 100px;
        }
    }
}


/* Images */
.services-search-row {
    background-image: none;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}


/* Footer - delete if you do not require one */
#service-directory-container {
    margin-bottom: -190px; // Total height of footer.
}

#footer {
    margin-top: 190px !important;
    height: 160px;
    max-height: 160px;
    padding: 0;
    .row {
        margin: 0;
    }

    .layout-container {
        padding: 20px;
        margin: 0 auto;
        max-width: 1900px;
        max-height: 125px;
    }

    .developer-credit {
        padding: 0 20px;
        padding-top: 10px;
        text-align: center;
    }

    .footer-links {
        list-style: none;
    }

    .footer-social-links-list {
        padding:0;
    }

    .footer-social-link-item {
        display: inline-block;
        .fa {
            font-size: 26px;
        }
    }

    .contact-details-footer .contact-details-item {
        display: block;
    }

    .footer-social-links {
        font-size: 12px;

        p {
            padding-left: 20px;
        }
    }

    .flag-icon {
        width: 40px;
    }

}

/* Layout */

.no-results-container {
    height: auto;
    padding-right: 20px;
    padding-bottom: 20px;
}

#search-sidebar ul.pagination > li > a {
    padding: 0 13px;
}

#search-sidebar ul.pagination > li:last-child {
    padding-right: 0;
}

#leaflet-map .map-info-overlay p.map-message {
    width: 100%;
    padding: 25px 0 0;
}

#leaflet-map .map-info-overlay {
    padding: 20px 30px;

    @media only screen and (max-width: 767px ) {
        display: none;
    }
}

@media only screen and (max-width: 1280px ) {
    .footer col-lg-8 {
        padding: 0;
    }
}

@media only screen and (max-width: 1400px ) {

    #leaflet-map .map-info-overlay p.map-message {
        font-size: 14px !important;
        line-height: 20px;
    }

}

@media only screen and (max-width: 1050px ) {

    #leaflet-map .map-info-overlay p.map-message {
        font-size: 14px !important;
        line-height: 20px;
    }

    #footer {

        .layout-container {
            padding-bottom: 0;

            .footer-social-links {
                position: absolute;
                bottom: 20px;
                left: 0;
            }

        }

        .developer-credit {
            width:100%;
            position: absolute;
            bottom: 10px;
            left: 0;
            border: 0;
        }
    }
}

@media only screen and (min-width: 767px ) {

    .footer i {
        display: none;
    }

    .footer .contact-details-item a {
        display: block;
    }
}

@media only screen and (max-width: 1199px ) {

    #footer .layout-container {
        padding: 10px;
    }

    .contact-details-footer {
        margin-bottom: 20px
    }

    .developer-credit {
        font-size: 12px;
    }

    .footer-social-links {

        padding-top: 5px;
        padding-bottom: 0;
        text-align: center;

        img {
            display: none;
        }

    }

}

@media only screen and (min-width: 767px ) {
    #leaflet-map {
        min-height: 300px;
    }
}

@media only screen and (max-width: 767px ) {

    .footer .email,
    .footer .phone {
        display: none;
    }

    .footer .contact-details-item a {
        display: inline-block;
    }

    .map-down-arrow {
        display: none;
    }
}

.services-search-row {
    .services-search-row-auxiliary {
        .btn-auxiliary {
            .inner {
                margin: 0 auto;
                width: 120px;

                @media only screen and (max-width: 960px ) {
                    width: 100px;
                    font-size: 14px;

                    i.fa {
                        font-size: 20px;
                    }

                    .text {
                        width: 50px;
                    }

                }
            }
        }


        @media only screen and (max-width: 1023px) {
            width: auto;

            .btn-auxiliary {
                width: 50px;

                .text {

                }
            }
        }
    }
}

.services-search-row .services-search-row-main form span.twitter-typeahead {
    width: 100%;

    @media only screen and (max-width: 767px ) {
        padding-right: 40px;
    }
}
.logo-container {

    .logo-img {
        position: relative;
        float: left;
        height: 40px;
        top: 10px;
        margin-right: 30px;
        left: 0;
        max-width: 20%;
        width: 100%;
        @media only screen and (min-width: 1200px ) {
            height: 40px;
            margin-right: 30px;
        }
        @media only screen and (max-width: 1199px ) {
            height: 30px;
            margin-right: 10px;
            top: 15px;
        }
        @media only screen and (max-width: 767px ) {
            display: none;
        }
    }

    @media only screen and (max-width: 1023px ) {
        float: left;
        margin-top: 1em;

        .logo-img {
            position: unset;
            max-width: unset;
        }
    }
}

.btn-default {
    height: 45px;
}

.services-search-row {
    min-width: auto;
}

#iss-search_form {
    &.searching {
        .fa-search {
            display: none;
        }
        .search-spinner {
            padding: 0;
            padding-right: 4px;
        }
        button:focus .search-spinner,
        button:hover .search-spinner {
            color: darken(@primary-reverse, 33%);
        }
    }
}

.services-search-row .services-search-row-main {
    form {
        width: 75%;

        @media only screen and (max-width: 1023px ) {
            width: auto;
        }
    }

    @media only screen and (max-width: 767px ) {
        width: 97%;

        form button {
            width: 40px;
            .text {
                display: none;
            }
        }

    }
}

.services-search-row .services-search-row-auxiliary {
    @media only screen and (max-width: 767px ) {
        display: none;
    }
}

.search-box-mobile {

    padding: 15px 10px;

    .search-services-box,
    .search-locations-box {
        height: 45px;
    }

    .search-services-box {
        border-right: none;
        border-bottom: @primary 1px solid;
    }
}
